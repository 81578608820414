<template>
  <div class="relative overflow-hidden report-vehicle-info">
    <div
      class="relative flex flex-col items-center justify-center h-64 overflow-hidden bg-oYellow"
    >
      <div class="payment-info-shape-1 top-left" />
      <div class="payment-info-shape-1 bottom-right" />

      <h3 class="text-trip-cost-data">
        {{
          details.trip ? `${currencySymbol}${details.trip.total_cost}` : `--`
        }}
      </h3>
      <h5 class="text-trip-cost-label">Trip Cost</h5>
    </div>

    <section class="grid grid-cols-2 border-b divide-x place-content-center">
      <div class="flex items-center justify-center p-5">
        <i class="text-xl fas fa-thumbs-up text-oGreen" />

        <div class="ml-3">
          <h4 class="font-medium text-oDark">
            --
            <!-- {{
              details.trip
                ? `${currencySymbol}${details.trip.total_cost}`
                : `--`
            }} -->
          </h4>
          <h5 class="text-sm font-medium text-gray-600">--</h5>
        </div>
      </div>

      <div class="flex items-center justify-center p-5">
        <i class="text-xl fas fa-clock text-oTeal" />

        <div class="ml-3">
          <h4 class="font-medium text-oDark">
            {{ details.trip ? details.trip.trip_duration : '--' }}
          </h4>
          <h5 class="text-sm font-medium text-gray-600">Trip Duration</h5>
        </div>
      </div>
    </section>

    <section class="mb-5 divide-y">
      <section class="payment-details-item">
        <h3 class="left">
          Unlock Charge
        </h3>
        <h3 class="right">
          {{
            details.trip
              ? `${currencySymbol}${details.trip.unlock_charge}`
              : '--'
          }}
        </h3>
      </section>

      <section class="payment-details-item">
        <h3 class="left">
          Trip Cost (
          {{ details.trip ? details.trip.trip_duration : '--' }}
          *
          {{
            details.trip
              ? `${currencySymbol}${details.trip.rate_per_minute}`
              : '--'
          }}
          )
        </h3>
        <h3 class="right">
          {{ details.trip ? `${currencySymbol}${details.trip.cost}` : '--' }}
        </h3>
      </section>

      <section class="payment-details-item">
        <h3 class="left">
          Reservation Cost
        </h3>
        <h3 class="right">
          {{
            details.trip
              ? `${currencySymbol}${details.trip.reservation_charge}`
              : `--`
          }}
        </h3>
      </section>

      <section class="payment-details-item">
        <h3 class="left-green">
          Discount
        </h3>
        <h3 class="right-green">
          {{
            details.trip ? `${currencySymbol}${details.trip.discount}` : `--`
          }}
        </h3>
      </section>

      <section class="payment-details-item">
        <h3 class="flex items-center left">
          <i class="mr-3 text-xl fas fa-wallet" />
          Payment
        </h3>
        <h3 class="right">
          {{ details.trip ? `--` : '--' }}
        </h3>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PaymentInfoBlock',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  components: {},
  computed: {
    currencySymbol() {
      return this.$org.default_currency.symbol
    },
  },
}
</script>

<style lang="scss" scoped>
.report-vehicle-info {
  min-height: 576px;
  height: 100%;
  border-radius: 22px;
  border: solid 1px #e4e4e4;
  background-color: #fefdff;

  & .text-muted {
    color: #a6a6ab;
  }
  & .text-trip-cost-data {
    font-size: 46px;
    font-weight: bold;
    @apply text-oDark;
  }
  & .text-trip-cost-label {
    font-size: 18px;
    font-weight: normal;
    @apply text-oDark;
  }

  & .payment-details-item {
    @apply flex items-center justify-between p-4;

    & .left {
      @apply font-medium text-oDark text-14px;
    }
    & .right {
      @apply font-medium text-oDark text-14px;
    }
    & .left-green {
      @apply font-medium text-oGreen text-14px;
    }
    & .right-green {
      @apply font-medium text-oGreen text-14px;
    }
  }

  & .payment-info-shape-1 {
    position: absolute;
    border: 38px solid #0fff9f;
    width: 170px;
    height: 170px;
    border-radius: 50%;

    &.top-left {
      top: -45px;
      left: -58px;
    }
    &.bottom-right {
      bottom: -45px;
      right: -58px;
    }
  }
}
</style>
